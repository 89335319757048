* {
  font-family: 'Poppins', sans-serif;
}

@import url('https://fonts.cdnfonts.com/css/candy-beans');
@import url('https://fonts.cdnfonts.com/css/liquidism-part-2');

#rotatingGlobe{
	margin: auto auto;
}

#iframe{
  width:100%;
  height:500px;
 }

.glow{
	width: 800px;
	height: 800px;
background: rgb(255,255,255);
background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
	position: absolute;
	left: 50%;
	top: 50%;
	transform:translate(-30%,-70%)
}


/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}


.active-link {
  color: #e02310 !important;
}

.navLink {
  color: #e02310 !important;
}

#earth {
	position: relative;
	width: 300px;
	height: 300px;
	border-radius: 50%;
	background: transparent url("https://i.imgur.com/Qk4UXcU.png");
	background-size: cover;
	box-shadow: inset 0px -20px 50px 10px #00ffff80,
        0px 0px 30px 6px #00ffff70;
	transform-style: preserve-3d;
	transform: rotate(20deg);
	animation: rotate 15s linear infinite;
}

@keyframes rotate {
	0% {
		background-position: 0 0;
	}

	100% {
		background-position: 530px 0;
	}
}

@media screen and (max-width: 499px) {
  .mouse{
    width: 2.3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     cursor: pointer;
    z-index: 10000;
    display: flex;
    
  }

  .mouse2{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
    padding-bottom: 30px;
   }
 
  .navbar {
    width: 10%;
    justify-content: flex-start;
    cursor: pointer;
  }

  iframe{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: center;
  }

  .navBarCon div {
    text-align: center;
    padding: 30px;
  }

  .navBarCon {
    background-color: rgba(0, 0, 0, 0.76);
    width: 100%;
    height: fit-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  .hidden {
    display: none;
  }

  .navBarCon img {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    display: flex;
    margin-left: auto;
    padding-right: 10px;
    padding-top: 10px;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .navBarCon img:hover {
    transform: scale(1.1);

  }

  .logo {
    width: 25%;
    height: 25%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  .fSection {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .halfGlobe {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: auto;
  }

  .yt {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 80%;
  }

  .discord4 {
    display: none;
  }

  .rmFish {
    width: 400px;
    height: 400px;
    animation: fishSwim 40s infinite;

  }

  .rmFish4 {
    width: 350px;
    height: 350px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 30px;
  }

  .rmPNew {
    color: white;
    font-size: 20px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 450px;
    height: 450px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
    background-color: #999999;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(230, 230, 230, 1) 69%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Sub {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
   margin-top: 10px;
   margin-left: 10px;
  /* z-index: -2;*/
  /*color: #ffffffa2;
 }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    display: none;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
  }

  .connect div {
    margin-left: 30px;
    margin-right: 30px;

    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  #fontSize:active {
    color: #e02310;
  }

  .colorChange {
    color: #e02310;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: rgb(0, 0, 0);
    padding: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
    margin-top: 100px;
    display: none;
  }

  .middleHMob {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
    margin-top: 50px;
  }

  .middleM {
    width: 500px;
    height: 172px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    z-index: 100;
    width: 100%;
  }

  .headersAndBtn {
    flex-flow: column nowrap;
    display: flex;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }


  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 500px;
    height: 194px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }

  .footer {}

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 13px;
    color: white;
    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 400px;
    height: 400px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .chart2 {
    width: 50%;
    width: 50%;
    height: 50%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
   }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .btn1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    transition: transform .5s;
    cursor: pointer;
  }

  .btn1:hover {
    transform: scale(1.1);

  }

  .section1 {
    background-color: black;
    padding-top: 50px;
    width: 100%;
  }

  .section2 {
    background: rgb(230, 230, 230);
    background: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 69%);
    padding-top: 50px;
    width: 100%;
  }

  .headersAndBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    flex-flow: column nowrap;
    display: flex;
  }

  .headersAndBtn3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    flex-flow: column nowrap;
    display: flex;
    width: 100%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
     width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
   }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
   }

  .boxWrap4Sub {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 60%;
  }

  .bars {
    display: flex;
    width: 50%;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .bars img {

    transition: transform .5s;
    margin-bottom: 20px;
  }

  .bars img:hover {
    transform: scale(1.1);
  }

  .applyBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    transition: transform .5s;
    cursor: pointer;
    width: 60%;
  }

  .applyBtn:hover {
    transform: scale(1.1);
  }

  .btnRead {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 10%;
    transition: transform .5s;
    cursor: pointer;
  }

  .btnRead:hover {
    transform: scale(1.1);
  }

  .middleCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .middleCon2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
  }

  .footerH {
    margin-bottom: 5%;
    width: 100%;
  }

  .iconSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    margin-top: 20px;
  }

  .iconSet img {

    transition: transform .5s;
    cursor: pointer;
  }

  .iconSet img:hover {
    transform: scale(1.1);

  }

  .iconMain {
    margin-bottom: 10%;
  }

  .headersList{
    display: none;
  }

  .headersList2 {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 50%;
  }

  .footerCon {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    display: none;
  }

  .footerConMob {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    flex-flow: column nowrap;
  }

  .footerConMob div {
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    cursor: pointer;
    transition: 0.1s ease-in;
    display: flex;
    text-align: center;

    margin-top: 5px;
  }

  .fSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .footerCon div:hover {
    color: #e02310;
  }

  .copyrightImg {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8%;
  }

  .books {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .members {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 15px;
  }

  .inputField {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 1%;
    border: 0.10px rgba(255, 255, 255, 0.300) solid;
    padding: 10px;
    width: 82%;
  }

  .brew {
    width: 75%;
    height: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
  }

  .input1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    text-align: left;
    width: 150px;
    font-size: 10px;
  }

  .inputs {
    margin-left: auto;
    margin-right: auto;
  }

  .input2 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    padding-right: 10px;
    text-align: center;
    width: 120px;
    font-size: 10px;

  }

  .pagesIcons {
    flex-flow: row nowrap;
    display: flex;
    width: fit-content;
     position: sticky;
    position: -webkit-sticky;
    bottom: 5%;
    z-index: 1000000000;
    margin-left: auto;
    margin-right: auto;
  }
  
  .pagesIconsImg {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 25%;
    margin-bottom: 25%;
    opacity: 1;
    z-index: 100000000000;
  }
  
  .onImg1 .pagesIconsImg,
  .onImg2 .pagesIconsImg,
  .onImg3 .pagesIconsImg,
  .onImg4 .pagesIconsImg {
    opacity: 0;
    z-index: 100000000000;
  }

  .onImg1{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP1.png') !important;
    margin-top: 25% !important;
    margin-bottom: 25% !important;
    z-index: 20000000000000000000000000000000000 !important;
    }
  
  .onImg2{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP2.png') !important;
  }
  
  .onImg3{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP3.png') !important;
  }
  
  .onImg4{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP4.png') !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .mouse{
    width: 2.3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     cursor: pointer;
    z-index: 10000;
    display: flex;
    
  }

  .mouse2{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
    padding-bottom: 30px;
   }
  iframe{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }
  .navbar {
    width: 10%;
    justify-content: flex-start;
    cursor: pointer;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: center;
  }

  .navBarCon div {
    text-align: center;
    padding: 30px;
  }

  .navBarCon {
    background-color: rgba(0, 0, 0, 0.76);
    width: 100%;
    height: fit-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  .hidden {
    display: none;
  }

  .navBarCon img {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    display: flex;
    margin-left: auto;
    padding-right: 10px;
    padding-top: 10px;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .navBarCon img:hover {
    transform: scale(1.1);

  }

  .logo {
    width: 25%;
    height: 25%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .fSection {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .halfGlobe {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: auto;
  }

  .yt {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 70%;
  }

  .discord4 {
    display: none;
  }

  .rmFish {
    width: 400px;
    height: 400px;
    animation: fishSwim 40s infinite;

  }

  .rmFish4 {
    width: 350px;
    height: 350px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 30px;
  }

  .rmPNew {
    color: white;
    font-size: 20px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 450px;
    height: 450px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
    background-color: #999999;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(230, 230, 230, 1) 69%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Sub {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
   margin-top: 10px;
   margin-left: 10px;
  /* z-index: -2;*/
  /*color: #ffffffa2;
 }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    display: none;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
  }

  .connect div {
    margin-left: 20px;
    margin-right: 20px;

    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  #fontSize:active {
    color: #e02310;
  }

  .colorChange {
    color: #e02310;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: rgb(0, 0, 0);
    padding: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }


  .middleH {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
    margin-top: 100px;
    display: none;
  }

  .middleHMob {
    width: 85%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .middleM {
    width: 500px;
    height: 172px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    z-index: 100;
    width: 100%;
  }

  .headersAndBtn {
    flex-flow: column nowrap;
    display: flex;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }


  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 500px;
    height: 194px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }

  .footer {}

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }


  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 13px;
    color: white;
    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 400px;
    height: 400px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .chart2 {
    width: 50%;
    width: 50%;
    height: 50%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
   }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .btn1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    transition: transform .5s;
    cursor: pointer;
  }

  .btn1:hover {
    transform: scale(1.1);

  }

  .section1 {
    background-color: black;
    padding-top: 50px;
    width: 100%;
  }

  .section2 {
    background: rgb(230, 230, 230);
    background: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 69%);
    padding-top: 50px;
    width: 100%;
  }

  .headersAndBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    flex-flow: column nowrap;
    display: flex;
  }

  .headersAndBtn3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    flex-flow: column nowrap;
    display: flex;
    width: 100%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
     width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
   }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
   }

  .boxWrap4Sub {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 60%;
  }

  .bars {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .bars img {
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .5s;
  }

  .bars img:hover {
    transform: scale(1.1);
  }

  .applyBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    transition: transform .5s;
    cursor: pointer;
    width: 40%;
    margin-bottom: 30px;

  }

  .applyBtn:hover {
    transform: scale(1.1);
  }

  .btnRead {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 10%;
    transition: transform .5s;
    cursor: pointer;
  }

  .btnRead:hover {
    transform: scale(1.1);
  }

  .middleCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .middleCon2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
  }

  .footerH {
    margin-bottom: 5%;
    width: 100%;
  }

  .iconSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    margin-top: 20px;
  }

  .iconSet img {

    transition: transform .5s;
    cursor: pointer;
  }

  .iconSet img:hover {
    transform: scale(1.1);

  }

  .iconMain {
    margin-bottom: 10%;
  }

  .headersList{
    display: none;
  }

  .headersList2 {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 50%;
  }

  .footerCon {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    display: none;
  }

  .footerConMob {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    flex-flow: column nowrap;
  }

  .footerConMob div {
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    cursor: pointer;
    transition: 0.1s ease-in;
    display: flex;
    text-align: center;

    margin-top: 5px;
  }

  .fSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .footerCon div:hover {
    color: #e02310;
  }

  .copyrightImg {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8%;
  }

  .books {
    width: 80%;
    height: 80%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .members {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 15px;
  }

  .inputField {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 1%;
    border: 0.10px rgba(255, 255, 255, 0.300) solid;
    padding: 10px;
    width: 82%;
  }

  .brew {
    width: 75%;
    height: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
  }

  .input1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    text-align: left;
    width: 200px;

  }

  .inputs {
    margin-left: auto;
    margin-right: auto;
  }

  .input2 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    padding-right: 10px;
    text-align: center;
    width: 150px;
  }
  .pagesIcons {
    flex-flow: row nowrap;
    display: flex;
    width: fit-content;
     position: sticky;
    position: -webkit-sticky;
    bottom: 5%;
    z-index: 1000000000;
    margin-left: auto;
    margin-right: auto;
  }
  
  .pagesIconsImg {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 25%;
    margin-bottom: 25%;
    opacity: 1;
    z-index: 100000000000;
  }
  
  .onImg1 .pagesIconsImg,
  .onImg2 .pagesIconsImg,
  .onImg3 .pagesIconsImg,
  .onImg4 .pagesIconsImg {
    opacity: 0;
    z-index: 100000000000;
  }

  .onImg1{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP1.png') !important;
    margin-top: 25% !important;
    margin-bottom: 25% !important;
    z-index: 20000000000000000000000000000000000 !important;
    }
  
  .onImg2{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP2.png') !important;
  }
  
  .onImg3{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP3.png') !important;
  }
  
  .onImg4{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP4.png') !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .mouse{
    width: 2.3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -3.7%;
    cursor: pointer;
    z-index: 10000;
    display: flex;
  }

  .mouse2{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }
  iframe{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }

  .navbar{
    display: none;
  }
  .middleHMob {
    display: none;
  }

  .footerConMob {
    display: none;
  }

  .fSection {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .halfGlobe {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: auto;
  }

  .yt {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 70%;
  }

  .discord4 {
    display: none;
  }

  .rmFish {
    width: 400px;
    height: 400px;
    animation: fishSwim 40s infinite;

  }

  .rmFish4 {
    width: 350px;
    height: 350px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 30px;
  }

  .rmPNew {
    color: white;
    font-size: 20px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 450px;
    height: 450px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
    background-color: #999999;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(230, 230, 230, 1) 69%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Sub {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
   margin-top: 10px;
   margin-left: 10px;
  /* z-index: -2;*/
  /*color: #ffffffa2;
 }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
  }

  .connect div {
    margin-left: 30px;
    margin-right: 30px;

    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  #fontSize:active {
    color: #e02310;
  }

  .colorChange {
    color: #e02310;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: rgb(0, 0, 0);
    padding: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
    margin-top: 100px;
  }

  .middleM {
    width: 500px;
    height: 172px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    z-index: 100;
    width: 100%;
  }

  .headersAndBtn {
    flex-flow: column nowrap;
    display: flex;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }


  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 500px;
    height: 194px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }

  .footer {}

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 12%;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 13px;
    color: white;
    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 400px;
    height: 400px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .chart2 {
    width: 50%;
    width: 50%;
    height: 50%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
   }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .btn1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    transition: transform .5s;
    cursor: pointer;
  }

  .btn1:hover {
    transform: scale(1.1);

  }

  .section1 {
    background-color: black;
    padding-top: 50px;
    width: 100%;
  }

  .section2 {
    background: rgb(230, 230, 230);
    background: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 69%);
    padding-top: 50px;
    width: 100%;
  }

  .headersAndBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    flex-flow: column nowrap;
    display: flex;
  }

  .headersAndBtn3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    flex-flow: column nowrap;
    display: flex;
    width: 100%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
     width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
   }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
   }

  .boxWrap4Sub {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 60%;
  }

  .bars {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .bars img {
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .5s;
  }

  .bars img:hover {
    transform: scale(1.1);
  }

  .applyBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    transition: transform .5s;
    cursor: pointer;
    width: 40%;
    margin-bottom: 30px;

  }

  .applyBtn:hover {
    transform: scale(1.1);
  }

  .btnRead {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 10%;
    transition: transform .5s;
    cursor: pointer;
  }

  .btnRead:hover {
    transform: scale(1.1);
  }

  .middleCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .middleCon2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
  }

  .footerH {
    margin-bottom: 5%;
    width: 100%;
  }

  .iconSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    margin-top: 20px;
  }

  .iconSet img {

    transition: transform .5s;
    cursor: pointer;
  }

  .iconSet img:hover {
    transform: scale(1.1);

  }

  .iconMain {
    margin-bottom: 10%;
  }

  .headersList{
    display: none;
  }

  .headersList2 {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 50%;
  }

  .footerCon {
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }

  .footerCon div {
    color: white;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: 0.1s ease-in;
  }

  .footerCon div:hover {
    color: #e02310;
  }

  .copyrightImg {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
  }

  .books {
    width: 80%;
    height: 80%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .members {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 15px;
  }

  .inputField {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
    border: 0.10px rgba(255, 255, 255, 0.300) solid;
    padding: 10px;
    width: 82%;
  }

  .brew {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;

  }

  .input1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    text-align: left;
    width: 200px;

  }

  .input2 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    padding-right: 10px;
    text-align: center;
    width: 150px;
  }

  .pagesIcons {
    flex-flow: row nowrap;
    display: flex;
    width: fit-content;
     position: sticky;
    position: -webkit-sticky;
    bottom: 5%;
    z-index: 1000000000;
    margin-left: auto;
    margin-right: auto;
  }
  
  .pagesIconsImg {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 25%;
    margin-bottom: 25%;
    opacity: 1;
    z-index: 100000000000;
  }
  
  .onImg1 .pagesIconsImg,
  .onImg2 .pagesIconsImg,
  .onImg3 .pagesIconsImg,
  .onImg4 .pagesIconsImg {
    opacity: 0;
    z-index: 100000000000;
  }

  .onImg1{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP1.png') !important;
    margin-top: 25% !important;
    margin-bottom: 25% !important;
    z-index: 20000000000000000000000000000000000 !important;
    }
  
  .onImg2{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP2.png') !important;
  }
  
  .onImg3{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP3.png') !important;
  }
  
  .onImg4{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP4.png') !important;
  }
  
 
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse{
    width: 2.3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -3.7%;
    cursor: pointer;
    z-index: 10000;
    display: flex;
  }

  .mouse2{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }
  
  iframe{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }

  .headersList2{
    display: none;
  }
  
  .middleHMob {
    display: none;
  }
  .navbar{
    display: none;
  }
  .pagesIconsMobile {
    display: none;
  }

  .footerConMob {
    display: none;
  }

  .halfGlobe {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: auto;
  }

  .yt {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 70%;
  }

  .discord4 {
    display: none;
  }

  .rmFish {
    width: 400px;
    height: 400px;
    animation: fishSwim 40s infinite;

  }

  .rmFish4 {
    width: 350px;
    height: 350px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 30px;
  }

  .rmPNew {
    color: white;
    font-size: 20px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 450px;
    height: 450px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
    background-color: #999999;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(230, 230, 230, 1) 69%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Sub {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
   margin-top: 10px;
   margin-left: 10px;
  /* z-index: -2;*/
  /*color: #ffffffa2;
 }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
  }

  .connect div {
    margin-left: 40px;
    margin-right: 40px;

    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  #fontSize:active {
    color: #e02310;
  }

  .colorChange {
    color: #e02310;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: rgb(0, 0, 0);
    padding: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
    margin-top: 40px;
  }

  .middleM {
    width: 500px;
    height: 172px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    z-index: 100;
    width: 100%;
  }

  .headersAndBtn {
    flex-flow: column nowrap;
    display: flex;
    width: fit-content;
  }


  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 500px;
    height: 194px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }

  .footer {}

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 10%;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 13px;
    color: white;
    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 400px;
    height: 400px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    height: 60%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .chart2 {
    width: 50%;
    width: 50%;
    height: 50%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
   }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .btn1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    transition: transform .5s;
    cursor: pointer;
  }

  .btn1:hover {
    transform: scale(1.1);

  }

  .section1 {
    background-color: black;
    padding-top: 50px;
    width: 50%;
  }

  .section2 {
    background: rgb(230, 230, 230);
    background: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 69%);
    padding-top: 50px;
    width: 50%;

  }

  .headersAndBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    flex-flow: column nowrap;
    display: flex;
    width: 65%;
  }

  .headersAndBtn3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    flex-flow: column nowrap;
    display: flex;
    width: 90%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
     width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
   }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
   }

  .boxWrap4Sub {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .bars {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .bars img {
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .5s;
  }

  .bars img:hover {
    transform: scale(1.1);
  }

  .applyBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    transition: transform .5s;
    cursor: pointer;
    width: 45%;
    margin-bottom: 30px;
  }

  .applyBtn:hover {
    transform: scale(1.1);
  }

  .btnRead {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 10%;
    transition: transform .5s;
    cursor: pointer;
  }

  .btnRead:hover {
    transform: scale(1.1);
  }

  .middleCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 100%;

  }

  .middleCon2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 100%;
  }

  .footerH {
    margin-bottom: 5%;
    width: 100%;
  }

  .iconSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    margin-top: 20px;
  }

  .iconSet img {

    transition: transform .5s;
    cursor: pointer;
  }

  .iconSet img:hover {
    transform: scale(1.1);

  }

  .iconMain {
    margin-bottom: 10%;
  }

  .headersList {
    margin-left: 10%;
    margin-top: auto;
    width: 20%;
    height: 20%;
  }

  .footerCon {
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }

  .footerCon div {
    color: white;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: 0.1s ease-in;
  }

  .footerCon div:hover {
    color: #e02310;
  }

  .copyrightImg {
    width: 32%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
  }

  .books {
    width: 80%;
    height: 80%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .members {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 15px;
  }

  .inputField {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
    border: 0.10px rgba(255, 255, 255, 0.300) solid;
    padding: 10px;
    width: 82%;
  }

  .brew {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;

  }

  .input1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    text-align: left;
    width: 300px;

  }

  .input2 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    padding-right: 10px;
    text-align: center;
    width: 150px;
  }

  .onImg1{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP1.png') !important;
    margin-top: 25% !important;
    margin-bottom: 25% !important;
    z-index: 20000000000000000000000000000000000 !important;
    }
  
  .onImg2{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP2.png') !important;
  }
  
  .onImg3{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP3.png') !important;
  }
  
  .onImg4{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP4.png') !important;
  }
  
  .pagesIcons {
    flex-flow: column nowrap;
    display: flex;
    width: fit-content;
     position: sticky;
    position: -webkit-sticky;
    bottom: 5%;
    z-index: 1000000000;
  }
  
  .pagesIconsImg {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 25%;
    margin-bottom: 25%;
    opacity: 1;
    z-index: 100000000000;
  }
  
  .onImg1 .pagesIconsImg,
  .onImg2 .pagesIconsImg,
  .onImg3 .pagesIconsImg,
  .onImg4 .pagesIconsImg {
    opacity: 0;
    z-index: 100000000000;
  }
  

  .pagesIcons3 {
    flex-flow: column nowrap;
    display: flex;
    width: fit-content;
    margin-top: -17%;
    margin-bottom: 7%;
  }

  .pagesIcons3 img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 25%;
    margin-bottom: 25%;

  }


  .pagesIcons2 {
    flex-flow: column nowrap;
    display: flex;
    width: fit-content;
    margin-top: -25%;
    margin-bottom: 7%;
  }

  .pagesIcons2 img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 25%;
    margin-bottom: 25%;

  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  
  .startEarning{
    width: 100%;
  }
  
  .onImg1{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP1.png') !important;
    margin-top: 25% !important;
    margin-bottom: 25% !important;
    z-index: 20000000000000000000000000000000000 !important;
    }
  
  .onImg2{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP2.png') !important;
  }
  
  .onImg3{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP3.png') !important;
  }
  
  .onImg4{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP4.png') !important;
  }
  
  .pagesIcons {
    flex-flow: column nowrap;
    display: flex;
    width: fit-content;
     position: sticky;
    position: -webkit-sticky;
    bottom: 5%;
  
  }
  
  .pagesIconsImg {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 25%;
    margin-bottom: 25%;
    opacity: 1;
  }
  
  .onImg1 .pagesIconsImg,
  .onImg2 .pagesIconsImg,
  .onImg3 .pagesIconsImg,
  .onImg4 .pagesIconsImg {
    opacity: 0;
  }
  
  iframe{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }

  .mouse{
    width: 2.3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -3.7%;
    cursor: pointer;
    z-index: 10000;
    display: flex;
  }

  .mouse2{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }
  
  .navBarCon{
    display: none;
  }
  .headersList2{
    display: none;
  }
  .navbar{
    display: none;
  }
  .middleHMob {
    display: none;
  }

  .footerConMob {
    display: none;
  }

  .pagesIconsMobile {
    display: none;
  }

  .yt {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .discord4 {
    display: none;
  }

  .rmFish {
    width: 400px;
    height: 400px;
    animation: fishSwim 40s infinite;

  }

  .rmFish4 {
    width: 350px;
    height: 350px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 30px;
  }

  .rmPNew {
    color: white;
    font-size: 20px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 450px;
    height: 450px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
    background-color: #999999;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(230, 230, 230, 1) 69%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Sub {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
  }

  .connect div {
    margin-left: 40px;
    margin-right: 40px;

    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .connect div:hover{
    color: #e02310;
  }

  #fontSize:hover {
    color: #e02310;
  }

  .colorChange {
    color: #e02310;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: rgb(0, 0, 0);
    padding: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 62%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
    margin-top: 40px;
  }

  .middleM {
    width: 500px;
    height: 172px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    z-index: 100;
    width: 100%;
  }

  .headersAndBtn {
    flex-flow: column nowrap;
    display: flex;
    width: fit-content;
  }


  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 500px;
    height: 194px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }

  .footer {}

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 7%;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 13px;
    color: white;
    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 400px;
    height: 400px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    height: 60%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .chart2 {
    width: 50%;
    width: 50%;
    height: 50%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
   }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .btn1 {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    transition: transform .5s;
    cursor: pointer;
  }

  .btn1:hover {
    transform: scale(1.1);

  }

  .section1 {
    background-color: black;
    padding-top: 50px;
    width: 100%;
  }

  .section2 {
    background: rgb(230, 230, 230);
    background: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 69%);
    padding-top: 50px;
    width: 100%;

  }

  .headersAndBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    flex-flow: column nowrap;
    display: flex;
    width: 65%;
  }

  .headersAndBtn3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    flex-flow: column nowrap;
    display: flex;
    width: 85%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
     width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
   }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
   }

  .boxWrap4Sub {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .bars {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .bars img {
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .5s;
  }

  .bars img:hover {
    transform: scale(1.1);
  }

  .applyBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    transition: transform .5s;
    cursor: pointer;
    width: 45%;
  }

  .applyBtn:hover {
    transform: scale(1.1);
  }

  .btnRead {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 10%;
    transition: transform .5s;
    cursor: pointer;
  }

  .btnRead:hover {
    transform: scale(1.1);
  }

  .middleCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 80%;
  }

  .middleCon2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 100%;
  }

  .footerH {
    margin-bottom: 5%;
    width: 100%;
  }

  .iconSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    margin-top: 20px;
  }

  .iconSet img {

    transition: transform .5s;
    cursor: pointer;
  }

  .iconSet img:hover {
    transform: scale(1.1);

  }

  .iconMain {
    margin-bottom: 10%;
  }

  .headersList {
    margin-left: 10%;
    margin-top: auto;
    width: 20%;
    height: 20%;
  }

  .footerCon {
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }

  .footerCon div {
    color: white;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: 0.1s ease-in;
  }

  .footerCon div:hover {
    color: #e02310;
  }

  .copyrightImg {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
  }

  .books {
    width: 90%;
    height: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .members {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .inputField {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
    border: 0.10px rgba(255, 255, 255, 0.300) solid;
    padding: 10px;
    width: 60%;
  }

  .brew {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;

  }

  .input1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    text-align: left;
    width: 220px;

  }

  .input2 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    padding-right: 10px;
    text-align: center;
    width: 150px;
  }

  .pagesIcons3 {
    flex-flow: column nowrap;
    display: flex;
    width: fit-content;
    margin-top: -17%;
    margin-bottom: 7%;
  }

  .pagesIcons3 img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 25%;
    margin-bottom: 25%;

  }


  .pagesIcons2 {
    flex-flow: column nowrap;
    display: flex;
    width: fit-content;
    margin-top: -25%;
    margin-bottom: 7%;
  }

  .pagesIcons2 img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 25%;
    margin-bottom: 25%;

  }
}

@media screen and (min-width: 1920px) {

  .mouse{
    width: 2.3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -3.7%;
    cursor: pointer;
    z-index: 10000;
    display: flex;
  }

  .mouse2{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .onImg1{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP1.png') !important;
    margin-top: 25% !important;
    margin-bottom: 25% !important;
    z-index: 20000000000000000000000000000000000 !important;
    }
  
  .onImg2{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP2.png') !important;
  }
  
  .onImg3{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP3.png') !important;
  }
  
  .onImg4{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP4.png') !important;
  }
  
  .pagesIcons {
    flex-flow: column nowrap;
    display: flex;
    width: fit-content;
     position: sticky;
    position: -webkit-sticky;
    bottom: 5%;
  
  }
  
  .pagesIconsImg {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 25%;
    margin-bottom: 25%;
    opacity: 1;
  }
  
  .onImg1 .pagesIconsImg,
  .onImg2 .pagesIconsImg,
  .onImg3 .pagesIconsImg,
  .onImg4 .pagesIconsImg {
    opacity: 0;
  }
  
  
  .middleHMob {
    display: none;
  }
  iframe{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }
  .headersList2{
    display: none;
  }
  .navbar{
    display: none;
  }
  .footerConMob {
    display: none;
  }

  .pagesIconsMobile {
    display: none;
  }

  .halfGlobe {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .discord4 {
    display: none;
  }

  .rmFish {
    width: 400px;
    height: 400px;
    animation: fishSwim 40s infinite;

  }

  .rmFish4 {
    width: 350px;
    height: 350px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 30px;
  }

  .rmPNew {
    color: white;
    font-size: 20px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 450px;
    height: 450px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
    background-color: #999999;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(230, 230, 230, 1) 69%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Sub {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
  }

  .connect div {
    margin-left: 40px;
    margin-right: 40px;

    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .connect div:hover {
    color: #e02310;
  }

  #fontSize:active {
    color: #e02310;
  }

  .colorChange {
    color: #e02310;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: rgb(0, 0, 0);
    padding: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
    margin-top: 40px;
  }

  .middleM {
    width: 500px;
    height: 172px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    z-index: 100;
    width: 100%;
  }

  .headersAndBtn {
    flex-flow: column nowrap;
    display: flex;
    width: 22%;
  }

  .yt {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 500px;
    height: 194px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }

  .footer {}

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 7%;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 16px;
    color: white;
    cursor: pointer;
    z-index: 10000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 400px;
    height: 400px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    height: 60%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .chart2 {
    width: 50%;
    width: 50%;
    height: 50%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
   }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .btn1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    transition: transform .5s;
    cursor: pointer;
  }

  .btn1:hover {
    transform: scale(1.1);

  }

  .section1 {
    background-color: black;
    padding-top: 50px;
    width: 100%;
  }

  .section2 {
    background: rgb(230, 230, 230);
    background: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 69%);
    padding-top: 50px;
    width: 100%;
    padding-bottom: 50px;

  }

  .headersAndBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    flex-flow: column nowrap;
    display: flex;
    width: 65%;

  }

  .headersAndBtn3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    flex-flow: column nowrap;
    display: flex;
    width: 85%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
     width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
   }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
   }

  .boxWrap4Sub {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .bars {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .bars img {
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .5s;
  }

  .bars img:hover {
    transform: scale(1.1);
  }

  .applyBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    transition: transform .5s;
    cursor: pointer;
    width: 45%;
  }

  .applyBtn:hover {
    transform: scale(1.1);
  }

  .btnRead {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 10%;
    transition: transform .5s;
    cursor: pointer;
  }

  .btnRead:hover {
    transform: scale(1.1);
  }

  .middleCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 80%;
  }

  .middleCon2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 100%;
  }

  .footerH {
    margin-bottom: 5%;
    width: 100%;
  }

  .iconSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    margin-top: 20px;
  }

  .iconSet img {

    transition: transform .5s;
    cursor: pointer;
  }

  .iconSet img:hover {
    transform: scale(1.1);

  }

  .iconMain {
    margin-bottom: 10%;
  }

  .headersList {
    margin-left: 10%;
    margin-top: auto;
    width: 20%;
    height: 20%;
  }

  .footerCon {
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }

  .footerCon div {
    color: white;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: 0.1s ease-in;
  }

  .footerCon div:hover {
    color: #e02310;
  }

  .copyrightImg {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
  }

  .books {
    width: 90%;
    height: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .members {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .inputField {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
    border: 0.10px rgba(255, 255, 255, 0.300) solid;
    padding: 10px;
    width: 52%;
  }

  .brew {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;

  }

  .input1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    text-align: left;
    width: 300px;

  }

  .input2 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    padding-right: 10px;
    text-align: center;
    width: 150px;
  }
  
  
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .onImg1{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP1.png') !important;
    margin-top: 25% !important;
    margin-bottom: 25% !important;
    z-index: 20000000000000000000000000000000000 !important;
    }
  
  .onImg2{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP2.png') !important;
  }
  
  .onImg3{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP3.png') !important;
  }
  
  .onImg4{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP4.png') !important;
  }
  
  .pagesIcons {
    flex-flow: column nowrap;
    display: flex;
    width: fit-content;
     position: sticky;
    position: -webkit-sticky;
    bottom: 5%;
  
  }
  
  .pagesIconsImg {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 25%;
    margin-bottom: 25%;
    opacity: 1;
  }
  
  .onImg1 .pagesIconsImg,
  .onImg2 .pagesIconsImg,
  .onImg3 .pagesIconsImg,
  .onImg4 .pagesIconsImg {
    opacity: 0;
  }
  
  
  .middleHMob {
    display: none;
  }
  iframe{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }
  .navbar{
    display: none;
  }
  .footerConMob {
    display: none;
  }
  .headersList2{
    display: none;
  }
  .footerH {
    width: 170%;
  }

  .pagesIconsMobile {
    display: none;
  }

  .footerH2 {
    width: 170%;
  }

  .btnDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .halfGlobe {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .discord4 {
    display: none;
  }

  .rmFish {
    width: 400px;
    height: 400px;
    animation: fishSwim 40s infinite;

  }

  .rmFish4 {
    width: 350px;
    height: 350px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 30px;
  }

  .rmPNew {
    color: white;
    font-size: 20px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 450px;
    height: 450px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
    background-color: #999999;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(230, 230, 230, 1) 69%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Sub {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
   margin-top: 10px;
   margin-left: 10px;
  /* z-index: -2;*/
  /*color: #ffffffa2;
 }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
  }

  .connect div {
    margin-left: 40px;
    margin-right: 40px;

    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .connect div:hover {
    color: #e02310;
  }

  #fontSize:active {
    color: #e02310;
  }

  .colorChange {
    color: #e02310;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: rgb(0, 0, 0);
    padding: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
    margin-top: 40px;
  }

  .middleM {
    width: 500px;
    height: 172px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    z-index: 100;
    width: 100%;
  }

  .headersAndBtn {
    flex-flow: column nowrap;
    display: flex;
    width: 30%;
  }


  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 500px;
    height: 194px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }

  .footer {}

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 7%;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 22px;
    color: white;
    cursor: pointer;
    z-index: 10000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 400px;
    height: 400px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 50%;
    height: 50%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .chart2 {
    width: 50%;
    width: 50%;
    height: 50%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
   }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .btn1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 5px;
    transition: transform .5s;
    cursor: pointer;
  }

  .btn1:hover {
    transform: scale(1.1);

  }

  .section1 {
    background-color: black;
    padding-top: 50px;
    width: 100%;
  }

  .section2 {
    background: rgb(230, 230, 230);
    background: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 69%);
    padding-top: 50px;
    width: 100%;

  }

  .headersAndBtn2 {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 50%;
    flex-flow: column nowrap;
    display: flex;

  }

  .yt {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .headersAndBtn3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    flex-flow: column nowrap;
    display: flex;
    width: 100%;
  }


  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
     width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
   }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
   }

  .boxWrap4Sub {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .bars {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .bars img {
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .5s;
    width: 12%;
  }

  .bars img:hover {
    transform: scale(1.1);
  }

  .applyBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    transition: transform .5s;
    cursor: pointer;
    width: 30%;
  }

  .applyBtn:hover {
    transform: scale(1.1);
  }

  .btnRead {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 10%;
    transition: transform .5s;
    cursor: pointer;
  }

  .btnRead:hover {
    transform: scale(1.1);
  }

  .middleCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 90%;
  }

  .middleCon2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 100%;
  }

  .footerH {
    margin-bottom: 5%;
  }

  .iconSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
  }

  .iconSet img {
    width: 18%;
    transition: transform .5s;
    cursor: pointer;

  }

  .iconSet img:hover {
    transform: scale(1.1);

  }

  .iconMain {
    margin-bottom: 10%;
  }

  .headersList {
    margin-left: 10%;
    margin-top: auto;
    width: 20%;
    height: 20%;
  }

  .footerCon {
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }

  .footerCon div {
    color: white;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 22px;
    cursor: pointer;
    transition: 0.1s ease-in;
  }

  .footerCon div:hover {
    color: #e02310;
  }

  .copyrightImg {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
  }

  .books {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .members {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    padding-bottom: 30px;
  }


  .inputField {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
    border: 0.10px rgba(255, 255, 255, 0.300) solid;
    padding: 20px;
    width: 60%;
    height: 80%;
  }

  .brew {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;

  }

  .input1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    text-align: left;
    width: 350px;
    font-size: 22px;
  }

  .input2 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    padding-right: 10px;
    text-align: center;
    width: 200px;
    font-size: 22px;

  }

 
}

@media screen and (min-width: 3840px) {
  .footerConMob {
    display: none;
  }
  iframe{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }
  .navbar{
    display: none;
  }
  .middleHMob {
    display: none;
  }
  .headersList2{
    display: none;
  }
  .footerH {
    width: 170%;
  }

  .pagesIconsMobile {
    display: none;
  }

  .footerH2 {
    width: 170%;
  }

  .btnDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .halfGlobe {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .discord4 {
    display: none;
  }

  .rmFish {
    width: 400px;
    height: 400px;
    animation: fishSwim 40s infinite;

  }

  .rmFish4 {
    width: 350px;
    height: 350px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 30px;
  }

  .rmPNew {
    color: white;
    font-size: 20px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 450px;
    height: 450px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
    background-color: #999999;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(230, 230, 230, 1) 69%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Sub {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
   margin-top: 10px;
   margin-left: 10px;
  /* z-index: -2;*/
  /*color: #ffffffa2;
 }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
  }

  .connect div {
    margin-left: 40px;
    margin-right: 40px;

    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .connect div:hover {
    color: #e02310;
  }

  #fontSize:active {
    color: #e02310;
  }

  .colorChange {
    color: #e02310;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: rgb(0, 0, 0);
    padding: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
   border-right: #2d363600;
   border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
    margin-top: 40px;
  }

  .middleM {
    width: 500px;
    height: 172px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    z-index: 100;
    width: 100%;
  }

  .headersAndBtn {
    flex-flow: column nowrap;
    display: flex;
    width: 20%;
  }


  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 500px;
    height: 194px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }

  .footer {}

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 7%;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 32px;
    color: white;
    cursor: pointer;
    z-index: 10000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 400px;
    height: 400px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 50%;
    height: 50%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .chart2 {
    width: 50%;
    width: 50%;
    height: 50%;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
   }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    max-width: 100%;
    min-height: 100%;
  }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .btn1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 5px;
    transition: transform .5s;
    cursor: pointer;
  }

  .btn1:hover {
    transform: scale(1.1);

  }

  .section1 {
    background-color: black;
    padding-top: 50px;
    width: 100%;
  }

  .section2 {
    background: rgb(230, 230, 230);
    background: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 69%);
    padding-top: 50px;
    width: 100%;

  }

  .headersAndBtn2 {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 40%;
    flex-flow: column nowrap;
    display: flex;

  }

  .yt {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .headersAndBtn3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    flex-flow: column nowrap;
    display: flex;
    width: 100%;
  }


  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
     width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
   }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
   }

  .boxWrap4Sub {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .bars {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .bars img {
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .5s;
    width: 12%;
  }

  .bars img:hover {
    transform: scale(1.1);
  }

  .applyBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    transition: transform .5s;
    cursor: pointer;
    width: 25%;
  }

  .applyBtn:hover {
    transform: scale(1.1);
  }

  .btnRead {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 10%;
    transition: transform .5s;
    cursor: pointer;
  }

  .btnRead:hover {
    transform: scale(1.1);
  }

  .middleCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .middleCon2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 100%;
  }

  .footerH {
    margin-bottom: 5%;
  }

  .iconSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
  }

  .iconSet img {
    width: 18%;
    transition: transform .5s;
    cursor: pointer;

  }

  .iconSet img:hover {
    transform: scale(1.1);

  }

  .iconMain {
    margin-bottom: 10%;
  }

  .headersList {
    margin-left: 10%;
    margin-top: auto;
    width: 20%;
    height: 20%;
  }

  .footerCon {
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }

  .footerCon div {
    color: white;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 22px;
    cursor: pointer;
    transition: 0.1s ease-in;
  }

  .footerCon div:hover {
    color: #e02310;
  }

  .copyrightImg {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
  }

  .books {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .members {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    padding-bottom: 30px;
  }


  .inputField {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
    border: 0.10px rgba(255, 255, 255, 0.300) solid;
    padding: 20px;
    width: 52%;
  }

  .brew {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;

  }

  .input1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    text-align: left;
    width: 350px;
    font-size: 22px;
  }

  .input2 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    padding-right: 10px;
    text-align: center;
    width: 200px;
    font-size: 22px;

  }

  .onImg1{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP1.png') !important;
    margin-top: 25% !important;
    margin-bottom: 25% !important;
    z-index: 20000000000000000000000000000000000 !important;
    }
  
  .onImg2{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP2.png') !important;
  }
  
  .onImg3{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP3.png') !important;
  }
  
  .onImg4{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    display: block !important; 
    background-image: url('./assets/activeP4.png') !important;
  }
  
  .pagesIcons {
    flex-flow: column nowrap;
    display: flex;
    width: fit-content;
     position: sticky;
    position: -webkit-sticky;
    bottom: 5%;
  
  }
  
  .pagesIconsImg {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 25%;
    margin-bottom: 25%;
    opacity: 1;
  }
  
  .onImg1 .pagesIconsImg,
  .onImg2 .pagesIconsImg,
  .onImg3 .pagesIconsImg,
  .onImg4 .pagesIconsImg {
    opacity: 0;
  }
  
}